import React, { useEffect, useState } from 'react'
import { useCreateBlogMutation } from '../../../redux/api/blogApi';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Button, message } from 'antd';
import DashboardLayout from '../DashboardLayout/DashboardLayout';
import useAuthCheck from '../../../redux/hooks/useAuthCheck';
import ImageUpload from '../../UI/form/ImageUpload';
import BlogIcon from '../../../images/blogIcon.png';
import { FaLink } from "react-icons/fa";
import { YoutubeLinkValid } from '../../../utils/Youtube link validation/YoutubeLinkValid';

const AddBlog = () => {
    const { data: userData } = useAuthCheck();
    const [createBlog, { isLoading, isError, error, isSuccess }] = useCreateBlogMutation();
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [selectedImage, setSelectedImage] = useState(null);
    const [file, setFile] = useState(null);
    const navigate = useNavigate();

    const onSubmit = async (data) => {
        try {
            if (!userData?.id) {
                message.error('Please login first');
                return;
            }

            if (!file) {
                message.error('Please select an image');
                return;
            }

            const formData = new FormData();
            formData.append('file', file);
            
            const blogData = {
                ...data,
                userId: userData.id
            };
            
            formData.append('data', JSON.stringify(blogData));

            await createBlog(formData).unwrap();
        } catch (err) {
            console.error('Error creating blog:', err);
            message.error(err?.data?.message || 'Failed to create blog');
        }
    };

    useEffect(() => {
        if (isSuccess) {
            message.success('Successfully Blog Added!');
            navigate('/dashboard/blogs');
        }
    }, [isSuccess, navigate]);

    return (
        <DashboardLayout>
            <div className="card mb-5 p-2 shadow-sm">
                <form className="row form-row" onSubmit={handleSubmit(onSubmit)}>
                    <div className="col-md-12">
                        <div className="form-group mb-2 card-label">
                            <label>Title <span className="text-danger">*</span></label>
                            <input 
                                placeholder='Title' 
                                {...register("title", { required: "Title is required" })} 
                                className="form-control" 
                            />
                            {errors.title && <span className="text-danger">{errors.title.message}</span>}
                        </div>
                    </div>

                    <div className="col-md-12">
                        <div className="form-group mb-2 card-label">
                            <label>Description <span className="text-danger">*</span></label>
                            <textarea 
                                placeholder='Description' 
                                {...register("description", { required: "Description is required" })} 
                                className="form-control" 
                                rows={5} 
                            />
                            {errors.description && <span className="text-danger">{errors.description.message}</span>}
                        </div>
                    </div>

                    <div className="col-md-12 d-lg-flex justify-content-lg-around">
                        <div className='d-flex gap-2 align-items-center col-12 col-lg-6'>
                            <div className="my-3">
                                <img 
                                    className='' 
                                    style={{ maxWidth: '150px' }} 
                                    src={selectedImage || BlogIcon} 
                                    alt="Blog" 
                                />
                            </div>
                            <div className="mt-3">
                                <ImageUpload setFile={setFile} setSelectedImage={setSelectedImage} />
                            </div>
                        </div>
                        <div className='mt-3 col-12 col-lg-6'>
                            <div className='d-flex justify-content-center align-content-center'>
                                <label>
                                    Youtube link <FaLink /> 
                                    <input 
                                        type='text' 
                                        placeholder='Enter youtube link' 
                                        {...register("link", { 
                                            validate: YoutubeLinkValid 
                                        })} 
                                        className="form-control mt-3"
                                    />
                                    {errors.link && <span className="text-danger">Invalid YouTube link</span>}
                                </label>
                            </div>
                        </div>
                    </div>

                    <div className='text-center my-3'>
                        <Button 
                            htmlType='submit' 
                            type="primary" 
                            size='large' 
                            loading={isLoading} 
                            disabled={isLoading}
                        >
                            {isLoading ? 'Saving...' : 'Save Changes'}
                        </Button>
                    </div>
                </form>
            </div>
        </DashboardLayout>
    );
};

export default AddBlog;