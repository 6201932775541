
import React, { useEffect, useMemo, useState } from "react";
import {
  FaTable,
  FaCalendarDay,
  FaUserInjured,
  FaHourglassStart,
  FaRegStar,
  FaUserCog,
  FaBlog,
  FaSignOutAlt,
  FaLock,
  FaHouseUser,
  FaClinicMedical,
} from "react-icons/fa";
import { NavLink, useNavigate } from "react-router-dom";
import useAuthCheck from "../../redux/hooks/useAuthCheck";
import { loggedOut } from "../../service/auth.service";
import { message } from "antd";
import "./DashboardSidebarMenu.css";  
const staffMenu = [
  { urlRedirect: "/dashboard", Icon: <FaTable />, title: "Dashboard" },
  //appointment and create blogs added for staff also
  // {urlRedirect: "/dashboard/appointments", Icon: <FaCalendarDay />, title: "Appointments"},
  // { urlRedirect: "/dashboard/blogs", Icon: <FaBlog />, title: "Blogs" },
];

const doctorMenu = [
  // { urlRedirect: "/dashboard", Icon: <FaTable />, title: "Dashboard" },
  {
    urlRedirect: "/dashboard/appointments",
    Icon: <FaCalendarDay />,
    title: "Appointments",
  },
  {
    urlRedirect: "/dashboard/my-patients",
    Icon: <FaUserInjured />,
    title: "My Patients",
  },
  {
    urlRedirect: "/dashboard/prescription",
    Icon: <FaUserInjured />,
    title: "Prescription",
  },
  {
    urlRedirect: "/dashboard/clinics",
    Icon: <FaClinicMedical />,
    title: "Clinics",
  },
  {
    urlRedirect: "/dashboard/schedule",
    Icon: <FaCalendarDay />,
    title: "Schedule Timings",
  },
  {
    urlRedirect: "/dashboard/invoices",
    Icon: <FaHourglassStart />,
    title: "Invoices",
  },
  { urlRedirect: "/dashboard/reviews", Icon: <FaRegStar />, title: "Reviews" },
  {
    urlRedirect: "/dashboard/profile-setting",
    Icon: <FaUserCog />,
    title: "Profile Settings",
  },
   { urlRedirect: "/dashboard/blogs", Icon: <FaBlog />, title: "Add Blogs" },
  {
    urlRedirect: "/dashboard/change-password",
    Icon: <FaLock />,
    title: "Change Password",
  },
];

const patientMenu = [
  { urlRedirect: "/dashboard", Icon: <FaTable />, title: "Dashboard" },
  {
    urlRedirect: "/dashboard/favourite",
    Icon: <FaHouseUser />,
    title: "Favourites",
  },
  {
    urlRedirect: "/dashboard/profile-setting",
    Icon: <FaUserCog />,
    title: "Profile Settings",
  },
  {
    urlRedirect: "/dashboard/change-password",
    Icon: <FaLock />,
    title: "Change Password",
  },
];

const DashboardSidebarMenu = ({ isdashboard }) => {
  const navigate = useNavigate();
  const { role } = useAuthCheck();
  const [isLoggedIn, setIsLogged] = useState(false);
  const authChecked = useAuthCheck();
  useEffect(() => {
    authChecked && setIsLogged(true);
  }, [authChecked]);

  const handleSignOut = () => {
    loggedOut();
    message.success("Successfully Logged Out");
    navigate("/");
    window?.location?.reload();
  };

  const commanMenu = [
    {
      urlRedirect: "/",
      Icon: <FaSignOutAlt />,
      title: "Logout",
      onClick: handleSignOut,
    },
  ];

  const menuByRole = useMemo(() => {
    switch (role) {
      case "staff":
        return staffMenu;
      case "doctor":
        return doctorMenu;
      case "patient":
        return patientMenu;
      default:
        return [];
    }
  }, [role]);

  const completeMenu = useMemo(
    () => [...menuByRole, ...commanMenu],
    [menuByRole]
  );

  return isdashboard ? (
    <ul>
      {completeMenu.map((item, index) => (
        <li key={`${index}-${item.title}`}>
          <NavLink
            to={item.urlRedirect}
            activeClassName="active"
            end
            onClick={item.onClick}
          >
            {item.Icon}
            <span className="spanWrapper">{item.title}</span>
          </NavLink>
        </li>
      ))}
    </ul>
  ) : (
    completeMenu.map((item, index) => (
      <li key={`${index}-${item.title}`}>
        <NavLink
          to={item.urlRedirect}
          className={({ isActive }) =>
            isActive ? "nav-link scrollto active" : ""
          }
          onClick={item.onClick}
        >
          {item.Icon}

          <span className="spanWrapper">{item.title}</span>
        </NavLink>
      </li>
    ))
  );
};

export default DashboardSidebarMenu;
