import { tagTypes } from "../tag-types";
import { baseApi } from "./baseApi";
import apiEndPOints from "../url";
import { getFromLocalStorage } from "../../utils/local-storage";

export const blogApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    createBlog: build.mutation({
      query: (data) => {
        const accessToken = getFromLocalStorage('accessToken');
        return {
          url: apiEndPOints.BLOG_CREATE,
          method: "POST",
          data: data,
          headers: {
            "Content-Type": "multipart/form-data",
            "Authorization": `Bearer ${accessToken}`
          },
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      invalidatesTags: [tagTypes.blogs],
    }),
    getAllBlogs: build.query({
      query: (arg) => ({
        url: apiEndPOints.BLOGS_ALL,
        method: "GET",
        params: arg,
      }),
      transformResponse: (response) => {
        return {
          blogs: response.data,
          meta: response.meta,
        };
      },
      providesTags: [tagTypes.blogs],
    }),
    getSingleBlog: build.query({
      query: (id) => ({
        url: apiEndPOints.BLOG_SINGLE(id),
        method: "GET",
      }),
      transformResponse: (response) => {
        return response?.data?.data || response?.data || response;
      },
      providesTags: [tagTypes.blogs],
    }),
    updateBlog: build.mutation({
      query: ({ data, id }) => {
        const accessToken = getFromLocalStorage('accessToken');
        return {
          url: apiEndPOints.BLOG_UPDATE(id),
          method: "PATCH",
          data: data,
          headers: {
            "Content-Type": "multipart/form-data",
            "Authorization": `Bearer ${accessToken}`
          },
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      invalidatesTags: [tagTypes.blogs],
    }),
    deleteBlog: build.mutation({
      query: (id) => {
        const accessToken = getFromLocalStorage('accessToken');
        return {
          url: apiEndPOints.BLOG_DELETE(id),
          method: "DELETE",
          headers: {
            "Authorization": `Bearer ${accessToken}`
          },
        };
      },
      invalidatesTags: [tagTypes.blogs],
    }),
    getBlogsByDoctor: build.query({
      query: (id) => ({
        url: apiEndPOints.BLOG_GETBY_DOCTOR(id),
        method: "GET",
        transformResponse: (response) => {
          return response.data;
        },
      }),
      providesTags: [tagTypes.blogs],
    }),
  }),
});

export const {
  useCreateBlogMutation,
  useDeleteBlogMutation,
  useGetAllBlogsQuery,
  useUpdateBlogMutation,
  useGetSingleBlogQuery,
  useGetBlogsByDoctorQuery,
} = blogApi;
