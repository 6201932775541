import React from "react";
import img from "../../images/doc/doctor 3.jpg";
import moment from "moment";
import { useGetPatientAppointmentsQuery } from "../../redux/api/appointmentApi";
import { Button, Tabs, Tag, Tooltip } from "antd";
import CustomTable from "../UI/component/CustomTable";
import { Link } from "react-router-dom";
import { clickToCopyClipBoard } from "../../utils/copyClipBoard";

const AppointmentList = () => {
  const { data, isLoading: pIsLoading } = useGetPatientAppointmentsQuery();

  const appointmentColumns = [
    {
      title: "Tacking Id",
      key: 10,
      width: 130,
      render: function (data) {
        return (
          <div>
            <Tag
              color="#87d068"
              className="text-uppercase"
              style={{ cursor: "pointer", fontSize: "13px" }}
              onClick={() => clickToCopyClipBoard(data?.trackingId)}
            >
              {data?.trackingId}
            </Tag>
          </div>
        );
      },
    },
    {
      title: "Doctor",
      key: 20,
      width: 140,
      render: function (data) {
        return (
          <>
            <div className="avatar avatar-sm mr-2 d-flex gap-2">
              <div>
                <img
                  className="avatar-img rounded-circle"
                  src={data?.doctor?.img || img}
                  alt=""
                />
              </div>
              <div>
                <h6 className="text-nowrap mb-0 capitalize-text">
                  {`${data?.doctor?.firstName} ${data?.doctor?.lastName}`}
                </h6>
                <p className="form-text">{data?.doctor?.designation}</p>
              </div>
            </div>
          </>
        );
      },
    },
    {
      title: "App Date",
      key: 22,
      width: 100,
      render: function (data) {
        return (
          <div>
            {moment(data?.scheduleDate).format("LL")}{" "}
            <span className="d-block text-info">{data?.scheduleTime}</span>
          </div>
        );
      },
    },
    {
      title: "Status",
      key: 24,
      width: 100,
      render: function (data) {
        return (
          <Tag color="#f50" className=".capitalize-tex">
            {data?.status}
          </Tag>
        );
      },
    },
    {
      title: "Action",
      key: 25,
      width: 100,
      render: function (data) {
        return (
          <Link to={`/dashboard/appointments/${data.id}`}>
            <Button type="primary">View</Button>
            {console.log(data)}
          </Link>
        );
      },
    },
  ];

  const items = [
    {
      key: "1",
      label: "Appointments",
      children: (
        <CustomTable
          loading={pIsLoading}
          columns={appointmentColumns}
          dataSource={data}
          showPagination={true}
          pageSize={10}
          showSizeChanger={true}
        />
      ),
    },
  ];
  return <Tabs defaultActiveKey="1" items={items} />;
};
export default AppointmentList;
