import Footer from "../../Shared/Footer/Footer";
import logo from "../../../images/logo1.png";
import "./BookingInvoice.css";
import { useParams } from "react-router-dom";
import { useGetAppointmentedPaymentInfoQuery } from "../../../redux/api/appointmentApi";
import moment from "moment";
import { Empty, Button } from "antd";
import Header from "../../Shared/Header/Header";
import { useRef } from "react";
import { FaPrint } from "react-icons/fa";
import ReactToPrint from "react-to-print";

const BookingInvoice = () => {
  const ref = useRef();
  const { id } = useParams();
  const { data, isLoading, isError } = useGetAppointmentedPaymentInfoQuery(id);
  console.log(data);

  let content = null;
  if (isLoading) content = <div>Loading ...</div>;
  if (!isLoading && isError) content = <div>Something went Wrong!</div>;
  if (!isLoading && !isError && !data) content = <Empty />;
  if (!isLoading && !isError && data)
    content = (
      <>
        <div className="col-lg-8 offset-lg-2">
          <div className="invoice-content">
            <div className="invoice-item">
              <div className="row">
                <div className="col-md-6">
                  <div className="invoice-logo">
                    <img src={logo} alt="" />
                  </div>
                </div>
                <div className="col-md-6">
                  {/* <div> */}
                  <p className="invoice-details text-md-end text-start">
                    <strong>Order:</strong> #00124 <br />
                    <strong>Issued:</strong>{" "}
                    {moment(data.createdAt).format("LL")}
                  </p>
                  {/* </div> */}
                </div>
              </div>
            </div>

            <div className="invoice-item">
              <div className="row">
                <div className="col-md-6">
                  <div className="invoice-info">
                    <strong className="customer-text">Invoice From</strong>
                    <p
                      className="invoice-details invoice-details-two"
                      style={{ textTransform: "capitalize" }}
                    >
                      Dr.{" "}
                      {data?.appointment?.doctor?.firstName
                        ? `${data?.appointment?.doctor?.firstName} ${data?.appointment?.doctor?.lastName}`
                        : " Of DoctorOnCall"}{" "}
                      {data?.appointment?.doctor?.address && (
                        <>
                          <br />
                          {data?.appointment?.doctor?.address + ", "}
                          <br />
                        </>
                      )}
                      {data?.appointment?.doctor?.city && (
                        <>
                          {data?.appointment?.doctor?.city + ", "}
                          <br />
                        </>
                      )}
                      {data?.appointment?.doctor?.country && (
                        <>
                          {data?.appointment?.doctor?.country}
                          <br />
                        </>
                      )}
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="invoice-info invoice-info2 text-md-end text-start">
                    <strong className="customer-text">Invoice To</strong>
                    <p
                      className="invoice-details text-md-end text-start"
                      style={{ textTransform: "capitalize" }}
                    >
                      {data?.appointment?.firstName +
                        " " +
                        data?.appointment?.lastName}{" "}
                      <br />
                      {data?.appointment?.address}
                      <br />
                      {/* {data?.appointment?.patient?.country} <br /> */}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="invoice-item">
              <div className="row">
                <div className="col-md-12">
                  <div className="invoice-info">
                    <strong className="customer-text">Payment Method</strong>
                    <p className="invoice-details invoice-details-two">
                      Cash
                      {/* {data?.paymentType} <br />
                                        XXXXXXXXXXXX-2541 <br />
                                        {data?.paymentMethod}<br /> */}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="invoice-item invoice-table-wrap">
              <div className="row">
                <div className="col-md-12">
                  <div className="table-responsive">
                    <table className="invoice-table table table-bordered">
                      <thead>
                        <tr>
                          <th>Description</th>
                          <th className="text-center">Doctor Fee</th>
                          <th className="text-center">VAT</th>
                          <th className="text-right">Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>General Consultation</td>
                          <td className="text-center">₹{data?.DoctorFee}</td>
                          <td className="text-center">₹{data?.vat}</td>
                          <td className="text-right">₹{data?.totalAmount}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="col-md-12 col-xl-12 me-auto">
                  <div className="table-responsive">
                    <table className="invoice-table-two table">
                      <tbody>
                        <tr>
                          <th>Subtotal:</th>
                          <td>
                            <span>₹{data?.totalAmount}</span>
                          </td>
                        </tr>
                        <tr>
                          <th>Discount:</th>
                          <td>
                            <span>0%</span>
                          </td>
                        </tr>
                        <tr>
                          <th>Total Amount:</th>
                          <td>
                            <span>₹{data?.totalAmount}</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="other-info">
              <h4>Other information</h4>
              <p className="text-muted mb-0">
                Thank you for entrusting your health to our care. It has been a
                privilege to assist you, and we appreciate your confidence in
                our services. Should you have any questions or need further
                assistance, please do not hesitate to reach out.
                <br />
                Wishing you good health and well-being.
              </p>
              <br />
              <p>
                For any inquiries, please reach out to us at:
                <br />
                Phone: +91 89885 55540
                <br />
                Email: drdkpatwa.dermatologist@gmail.com
              </p>
            </div>
          </div>
        </div>
      </>
    );
  return (
    <>
      <Header />
      <div
        className="content"
        style={{ marginBottom: "7rem", marginTop: "10rem" }}
      >
        <div
          className="d-flex justify-content-end mb-4"
          style={{ marginRight: "8rem" }}
        >
          <ReactToPrint
            bodyClass="print-agreement"
            content={() => ref.current}
            trigger={() => (
              <Button type="primary" icon={<FaPrint />}>
                {" "}
                Print
              </Button>
            )}
          />
        </div>
        <div className="container-fluid" ref={ref}>
          <div className="row">{content}</div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default BookingInvoice;
