import React, { useEffect, useMemo } from "react";
import "./BookDoctor.css";
import { Link } from "react-router-dom";
import { useGetDoctorsQuery } from "../../../redux/api/doctorApi";
import {
  FaLocationArrow,
  FaCheckCircle,
  FaRegHeart,
  FaHeart,
  FaRupeeSign,
  FaClock,
} from "react-icons/fa";
import {
  useAddFavouriteMutation,
  useRemoveFavouriteMutation,
  useGetFavouriteQuery,
} from "../../../redux/api/favouriteApi";
import { useGetAllReviewsQuery } from "../../../redux/api/reviewsApi";
import StarRatings from "react-star-ratings";
import { message } from "antd";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper/modules";
import avatar from "../../../images/avatar.jpg";
import { calculateRatingsData } from "../../../utils/calculateRatingsData";

// Import Swiper styles
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/navigation";

const BookDoctor = () => {
  const { data, isError, isLoading } = useGetDoctorsQuery({ limit: 10 });
  const doctors = data?.doctors;
  const [
    addFavourite,
    { isSuccess, isLoading: FIsLoading, isError: fIsError, error },
  ] = useAddFavouriteMutation();
  const [
    removeFavourite,
    {
      isSuccess: rIsSucess,
      isLoading: remIsLoading,
      isError: remIsError,
      error: rError,
    },
  ] = useRemoveFavouriteMutation();
  const { data: favouriteData } = useGetFavouriteQuery();

  // doctorReviewDetails
  const {
    data: rData,
    isError: rIsError,
    isLoading: rIsLoading,
  } = useGetAllReviewsQuery();

  // handle add favorite and remove favorite
  const handleAddFavourite = (id) => {
    addFavourite({ doctorId: id });
  };

  const handleRemoveFavourite = (id) => {
    removeFavourite({ doctorId: id });
  };

  useEffect(() => {
    if (!FIsLoading && fIsError) {
      message.error(error?.data?.message);
    }
    if (isSuccess) {
      message.success("Successfully Favourite Added!");
    }
  }, [isSuccess, fIsError, FIsLoading, error?.data?.message]);

  useEffect(() => {
    if (!remIsLoading && remIsError) {
      message.error(rError?.data?.message);
    }
    if (rIsSucess) {
      message.success("Successfully removed from favourites!");
    }
  }, [rIsSucess, remIsError, remIsLoading, rError?.data?.message]);

  // Helper function to check if a doctor is in the favorites list
  const isFavourite = (doctorId) => {
    return favouriteData?.some((fav) => fav.doctorId === doctorId);
  };

  // Ratings data
  const { averageRatings, reviewCounts } = useMemo(() => {
    if (!rIsLoading && !rIsError && rData) {
      return calculateRatingsData(rData);
    }
    return { averageRatings: {}, reviewCounts: {} };
  }, [rData, rIsLoading, rIsError]);

  // Duplicate the doctors array if less than 4 items
  const displayDoctors = useMemo(() => {
    let tempDoctors = doctors;
    while (tempDoctors?.length < 4 && tempDoctors?.length > 0) {
      tempDoctors = [...tempDoctors, ...doctors];
    }
    return tempDoctors;
  }, [doctors]);

  // what to render
  let content = <div></div>;
  if (!isLoading && isError) content = <div>Something Went Wrong !</div>;
  if (!isLoading && !isError && doctors?.length === 0)
    content = <div>Empty</div>;
  if (!isLoading && !isError && doctors?.length > 0)
    content = (
      <>
        {displayDoctors &&
          displayDoctors?.map((item) => (
            <SwiperSlide key={item.id}>
              <div className="profile-widget">
                <div className="doc-img">
                  <Link to={`/doctors/profile/${item?.id}`}>
                    {item?.img ? (
                      <img
                        className="img-fluid w-100 h-100"
                        alt=""
                        src={item.img}
                      />
                    ) : (
                      <img
                        className="img-fluid w-100 h-100"
                        alt="Doctor"
                        src={avatar}
                      />
                    )}
                  </Link>
                  <a
                    style={{ cursor: "pointer" }}
                    className="position-absolute top-0 end-0 me-2"
                    onClick={() =>
                      isFavourite(item?.id)
                        ? handleRemoveFavourite(item?.id)
                        : handleAddFavourite(item?.id)
                    }
                  >
                    {isFavourite(item?.id) ? <FaHeart /> : <FaRegHeart />}
                  </a>
                </div>
                <div className="pro-content">
                  <h3 className="title">
                    <Link to={`/doctors/profile/${item?.id}`}>
                      <a>{item?.firstName + " " + item?.lastName}</a>
                    </Link>
                    <FaCheckCircle className="verified" />
                  </h3>

                  <div>
                    <p
                      className="speciality desigHeight overflow-auto"
                      style={{ maxHeight: "50px" }}
                    >
                      {item?.designation}, {item?.specialization}
                    </p>
                  </div>

                  <div className="w-100 d-flex align-items-center">
                    <StarRatings
                      rating={parseFloat(averageRatings[item.id] || 0)}
                      starRatedColor="#f4c150"
                      numberOfStars={5}
                      name="rating"
                      className="star"
                      starDimension="20px"
                      starSpacing="5px"
                    />
                    <span className="d-inline-block text-secondary mt-2">
                      ({reviewCounts[item.id] || 0})
                    </span>
                  </div>
                  <ul className="available-info">
                    <li>
                      <FaLocationArrow className="icon" />
                      {item?.address ? item?.address : " "}
                    </li>
                    {/* <li>
										<FaClock className='icon' /> Available on Fri, 22 Mar
									</li> */}
                    <li>
                      <FaRupeeSign className="icon" />
                      {item?.price ? "₹" + item?.price : ""}
                    </li>
                  </ul>
                  <div className="d-flex justify-content-between align-items-center">
                    <Link
                      to={`/doctors/profile/${item?.id}`}
                      className="btn  btn-outline-info btn-sm view-profile-btn"
                    >
                      Profile
                    </Link>
                    <Link
                      to={`/booking/${item?.id}`}
                      className="btn btn-sm book-btn"
                    >
                      Book
                    </Link>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
      </>
    );
  return (
    <section className="section-doctor container">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-md-3 col-lg-3">
            <div className="mb-2 section-title text-center">
              <h2>Book Our Doctor</h2>
              <p className="m-0 text-secondary">Book an appointment.</p>
            </div>
            <div className="form-text">
              <p>
                Book an appointment with our highly qualified doctors to receive
                the best medical care tailored to your needs.
              </p>
              <p>
                Your health and well-being are our top priorities, and we're
                here to provide exceptional care every step of the way.
              </p>
              <div className="text-center text-md-start my-3 my-md-0">
                <Link
                  to={"/doctors"}
                  className="more-btn text-center text-md-start"
                >
                  See More
                </Link>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-9 col-lg-9">
            <div className="d-flex justify-content-center align-items-center gap-3 border-0">
              {content && (
                <Swiper
                  spaceBetween={10}
                  slidesPerView={1}
                  modules={[Navigation, Autoplay]}
                  navigation={true}
                  loop={true}
                  centeredSlides={true}
                  autoplay={{ delay: 5000, disableOnInteraction: false }}
                  breakpoints={{
                    640: { slidesPerView: 2 },
                    768: { slidesPerView: 2 },
                    1024: { slidesPerView: 3 },
                  }}
                >
                  {content}
                </Swiper>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BookDoctor;
